import userResolutionLogService from "holocene-services/userResolutionLog.service";
import { useMutation } from "react-query";

const useUserResolutionLog = () => {
    const logUserResolutionMutation = useMutation({
        mutationFn: userResolutionLogService.createOrUpdateUserResolutionLog
      });
    return logUserResolutionMutation;
};

export default useUserResolutionLog;
